<template>
  <div class="indexbox">
    <!-- 变美无需等待 -->
    <div class="bacbox">
      <div class="bacone">
        <!-- <img class="imgbox" src="https://www.bonetec.cn/static/imgs/home/bac1.png" alt=""> -->
        <img class="imgbox" src="@/assets/imgs/home/bac1.png" alt="">
      </div>

      <!-- 定位文字 -->
      <div class="postext animate__animated animate__backInDown">
        <!-- <div class="postext"> -->
        <div class="btext">变美无需等待</div>
        <div class="stext">个性化定制无托槽隐形矫治器</div>
        <div class="stext">航空航天技术应用于口腔矫正美学的领导者</div>
      </div>
    </div>

    <!-- 你，准备好了吗？ -->
    <div class="readybox">
      <div class="ready">
        <div class="text">你，准备好了吗？</div>
        <div class="startbox">
          <img class="startimg" :src="currimg" @mouseenter="enter" @mouseleave="leave" @click="start" alt="">
        </div>
      </div>
    </div>

    <!-- 选项部分 -->
    <div class="smilebox">
      <!-- 第1步 -->
      <div class="box box1">
        <div class="content">

          <!-- l -->
          <div class="left-box">
            <p class="qus">和好友合影时，</p>
            <p class="qus">您的微笑方式是？</p>
            <ul class="ulbox">
              <li class="libox" v-for="(v, i) in list1" :key="i">
                <label class="labelbox">
                  <input v-model="one" :value="v" class="radiobox" type="radio" name="info">{{ v }}
                </label>
              </li>
            </ul>
            <div class="next" @click="next(1)">下一题</div>
          </div>

          <!-- r -->
          <div class="right-box pic1"></div>

        </div>
      </div>

      <!-- 第2步 -->
      <div class="box box2">
        <div class="content">
          <div class="right-box pic2"></div>
          <div class="left-box">
            <p class="qus">吃苹果的时候，</p>
            <p class="qus">你会发现：</p>
            <ul class="ulbox">
              <li class="libox" v-for="(v, i) in list2" :key="i">
                <label class="labelbox">
                  <input v-model="two" :value="v" class="radiobox" type="radio" name="info">{{ v }}
                </label>
              </li>
            </ul>
            <div class="next" @click="next(2)">下一题</div>
          </div>
        </div>
      </div>

      <!-- 第3步 -->
      <div class="box box3">
        <div class="content">
          <div class="left-box">
            <p class="qus">刷牙时，</p>
            <p class="qus">你会发现：</p>
            <ul class="ulbox">
              <li class="libox" v-for="(v, i) in list3" :key="i">
                <label class="labelbox">
                  <input v-model="three" :value="v" class="radiobox" type="radio" name="info">{{ v }}
                </label>
              </li>
            </ul>
            <div class="next" @click="next(3)">下一题</div>
          </div>
          <div class="right-box pic3"></div>
        </div>
      </div>

      <!-- 第4步 -->
      <div class="box box4">
        <div class="content">
          <div class="right-box pic4"></div>
          <div class="left-box">
            <p class="qus">心情好时来张自拍，</p>
            <p class="qus">你会发现：</p>
            <ul class="ulbox">
              <li class="libox" v-for="(v, i) in list4" :key="i">
                <label class="labelbox">
                  <input v-model="four" :value="v" class="radiobox" type="radio" name="info">{{ v }}
                </label>
              </li>
            </ul>
            <div class="next" @click="next(4)">下一题</div>
          </div>
        </div>
      </div>

      <!-- 第5步 最后一步 -->
      <div class="box box5">
        <div class="content">
          <div class="left-box">
            <p class="qus">照镜子时，</p>
            <p class="qus">你的牙齿是这样的:</p>
            <div class="next" @click="result">获取报告</div>
          </div>

          <!-- 九宫格选项 -->
          <div class="image-container">
            <div v-for="(val, index) in list5" :key="index" class="image-item">
              <label :for="'image-' + index" class="image-wrapper">
                <img :src="val.imgs" alt="img" />
                <input type="radio" :id="'image-' + index" :value="val.text" v-model="select" class="radiobox" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import "@/assets/css/home.css";

export default {
  data() {
    return {
      one: "",
      two: "",
      three: "",
      four: "",
      select: "",
      list1: ["抿嘴微笑，笑不露齿", "浅浅微笑，只露前牙", "敢于绽放最美笑容"],
      list2: ["整齐的齿痕", "歪歪扭扭的齿痕", "有很重的下颌齿痕"],
      list3: [
        "牙齿黄黄，一刷就牙龈出血",
        "牙齿“歪七扭八”，不容易刷干净",
        "牙齿白白，牙龈粉粉",
      ],
      list4: [
        "习惯单侧脸自拍",
        "发现照片中的自己“小下巴”",
        "发现照片中的自己脸有点歪",
      ],
      list5: [
        {
          // imgs: '@/assets/imgs/home/AI反合.png',
          imgs: require("../../assets/imgs/home/AI反合.png"),
          text: "前牙反颌",
        },
        {
          imgs: require("../../assets/imgs/home/AI前突.png"),
          // imgs: '@/assets/imgs/home/AI前突.png',
          text: "前突",
        },

        {
          imgs: require("../../assets/imgs/home/AI后牙反合.png"),
          // imgs: '@/assets/imgs/home/AI后牙反合.png',
          text: "后牙反颌",
        },
        {
          imgs: require("../../assets/imgs/home/AI后牙锁𬌗.png"),
          // imgs: '@/assets/imgs/home/AI后牙锁𬌗.png',
          text: "后牙锁颌",
        },
        {
          imgs: require("../../assets/imgs/home/AI开合.png"),
          // imgs: '@/assets/imgs/home/AI开合.png',
          text: "开颌",
        },
        {
          imgs: require("../../assets/imgs/home/AI间隙.png"),
          // imgs: '@/assets/imgs/home/AI间隙.png',
          text: "间隙",
        },
        {
          imgs: require("../../assets/imgs/home/AI深覆盖.png"),
          // imgs: '@/assets/imgs/home/AI深覆盖.png',
          text: "深覆盖",
        },
        {
          imgs: require("../../assets/imgs/home/AI深覆𬌗.png"),
          // imgs: '@/assets/imgs/home/AI深覆𬌗.png',
          text: "深覆𬌗",
        },
        {
          imgs: require("../../assets/imgs/home/AI拥挤.png"),
          // imgs: '@/assets/imgs/home/AI拥挤.png',
          text: "牙列拥挤",
        },
      ],
      currimg: require("../../assets/imgs/home/start.png"),
      hoverimg: require("../../assets/imgs/home/hover.png"),
    };
  },
  methods: {
    start() {
      document.querySelector(".bacbox").style.display = "none";
      document.querySelector(".readybox").style.display = "none";
      document.querySelector(".box1").style.display = "flex";
    },
    enter() {
      this.currimg = this.hoverimg;
    },
    leave() {
      this.currimg = require("../../assets/imgs/home/start.png");
    },
    next(i) {
      if (
        (this.one && i == 1) ||
        (this.two && i == 2) ||
        (this.three && i == 3) ||
        (this.four && i == 4)
      ) {
        console.log(i);
        let box = document.querySelectorAll(".box");
        box.forEach((a, b) => {
          a.style.display = "none";
          box[i].style.display = "flex";
          // box[i].classList.add("show")
        });
      }
    },
    result() {
      if (this.select) {
        // this.$router.push("/result")
        this.$router.push({
          path: "/result",
          query: {
            myselece: this.select,
          },
        });
      }
    },
  },
  mounted() {
    // document.querySelector(".smilebox").style.display = "none"
  },
};
</script>

<style lang="less" scoped>
.indexbox {
  /* 变美无需等待 */
  .bacbox {
    position: relative;

    .bacone {
      // width: 100%;
      width: 100vw;
      // height: 885px;
      height: calc(100vh - 80px);
    }

    /*  定位文字 */
    .postext {
      position: absolute;
      left: 20%;
      top: 34%;
      // text-align: center;

      .btext {
        font-size: 90px;
        font-weight: 500;
        color: #fdfdfd;
        margin-bottom: 80px;
        font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
      }

      .stext {
        font-size: 24px;
        font-weight: 400;
        color: #fdfdfd;
        margin-bottom: 25px;
      }
    }
  }

  /* 你，准备好了吗？ */
  .readybox {
    width: 100vw;
    position: relative;
    height: 813px;
    background: url("@/assets/imgs/home/smile.png") no-repeat center;
    background-size: 100% 100%;

    .ready {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      text-align: center;

      .text {
        font-size: 100px;
        color: #fff;
      }

      .startbox {
        margin-top: 65px;
      }
    }
  }

  /* 选项部分 */
  .smilebox {
    .box1 {
      background: url("@/assets/imgs/home/step1.png") no-repeat center;

      // .left-box {
      //   padding-top: 0px !important;
      // }

      .right-box {
        height: 620px;
      }
    }

    .box2 {
      background: url("@/assets/imgs/home/step2.png") no-repeat center;

      .right-box {
        /* height: 800px; */
        height: calc(100vh - 300px);
      }
    }

    .box3 {
      background: url("@/assets/imgs/home/step3.png") no-repeat center;

      // .left-box {
      //   padding-top: 0px !important;
      // }

      .right-box {
        height: 620px;
      }
    }

    .box4 {
      background: url("@/assets/imgs/home/step4.png") no-repeat center;

      .right-box {
        /* height: 800px; */
        height: calc(100vh - 300px);
      }
    }

    .box5 {
      background: url("@/assets/imgs/home/step5.png") no-repeat center;
    }

    .box {
      /* height: 816px; */
      padding-top: 30px;
      height: calc(100vh - 80px);
      background-size: 100% 100%;
      display: flex;
      // align-items: center;
      display: none;

      .content {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;

        /* 左边文字 */
        .left-box {
          padding-top: 50px;

          .qus {
            font-size: 36px;
            color: #242424;
            margin-bottom: 20px;
          }

          .libox {
            /* display: flex;
                        align-items: center; */
            color: #9e9e9e;
            font-size: 24px;
            margin-top: 50px;

            .labelbox {
              display: flex;
              align-items: center;

              .radiobox {
                position: relative;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: 22px;
                height: 22px;
                border: 2px solid #0862f3;
                margin-right: 10px;

                &:checked {
                  background: #0862f3;
                }

                &:checked::before {
                  content: "";
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  width: 10px;
                  height: 6px;
                  border: 2px solid #fff;
                  transform: rotate(-45deg);
                  border-right: none;
                  border-top: none;
                }
              }
            }
          }

          .next {
            margin-top: 40px;
            text-align: center;
            color: #0862f3;
            width: 200px;
            height: 60px;
            line-height: 60px;
            border-radius: 30px;
            opacity: 1;
            border: 1px solid #0862f3;
          }
        }

        /* 右边图片 */
        .right-box {
          width: 600px;
          /* height: 620px; */
        }

        .pic1 {
          background: url("@/assets/imgs/home/pic1.png") no-repeat center;
          background-size: 100% 100%;
        }

        .pic2 {
          background: url("@/assets/imgs/home/pic2.png") no-repeat center;
          background-size: 100% 100%;
        }

        .pic3 {
          background: url("@/assets/imgs/home/pic3.png") no-repeat center;
          background-size: 100% 100%;
        }

        .pic4 {
          background: url("@/assets/imgs/home/pic4.png") no-repeat center;
          background-size: 100% 100%;
        }
      }
    }

    .box5 {
      .content {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;

        /* 生成报告 */
        .next {
          margin-top: 100px !important;
        }

        .image-container {
          // width: 600px;
          // height: 600px;
          width: 880px;
          height: 700px;
          display: flex;
          flex-wrap: wrap;

          .a {
            color: black;
            font-size: 50px;
          }

          .image-item {
            width: 24%;
            /* 每行显示三个 */
            text-align: center;
            // margin: 10px;
            // margin: 20px 40px;
            // margin-bottom: 30px;
            margin-left: 80px;
            display: flex;
            align-items: end;
          }

          .image-wrapper {
            cursor: pointer;
            position: relative;
          }

          .image-item img {
            width: 100%;
            /* height: auto; */
          }

          /* 单选框样式 */
          .radiobox {
            margin-top: 20px;
            position: relative;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 22px;
            height: 22px;
            border: 2px solid #0862f3;
            margin-right: 10px;

            &:checked {
              background: #0862f3;
            }

            &:checked::before {
              content: "";
              position: absolute;
              top: 3px;
              left: 3px;
              width: 10px;
              height: 6px;
              border: 2px solid #fff;
              transform: rotate(-45deg);
              border-right: none;
              border-top: none;
            }
          }

          /* input[type="radio"] {
                display: none;
            } */
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .indexbox {
    /* 变美无需等待 */
    .bacbox {
      position: relative;

      .imgbox {
        display: none;
      }

      .bacone {
        width: 100%;
        // height: 300px;
        height: calc(50vh - 50px);
        background: url("@/assets/imgs/home/bac1.png") no-repeat center;
        // background: url("@/assets/imgs/home/bac1.png") no-repeat center;
        background-size: cover;
        background-position: 0%;
        //  background-size: 100% 100%;
      }

      /*  定位文字 */
      .postext {
        position: absolute;
        left: 35px;
        top: 85px;
        // text-align: center;

        .btext {
          font-size: 36px;
          color: #fdfdfd;
          margin-bottom: 20px;
        }

        .stext {
          font-size: 16px;
          font-weight: 400;
          color: #fdfdfd;
          margin-bottom: 10px;
        }
      }
    }

    /* 你，准备好了吗？ */
    .readybox {
      width: 100%;
      /* height: 400px; */
      // height: calc(100vh - 350px);
      // height: calc(50vh - 50px);
      height: 50vh;

      background: url("@/assets/imgs/home/smile.png") no-repeat;
      background-size: cover;
      // background-size: 100% 100%;

      .ready {
        width: 80%;
        text-align: center;

        .text {
          font-size: 36px;
          color: #fff;
        }

        .startbox {
          margin-top: 35px;
          // .startimg {
          //   transform: scalex(0.8);
          // }
        }
      }
    }

    /* 选项部分 */
    .smilebox {
      .box1 {
        background: url("@/assets/imgs/home/step1.png") no-repeat center;

        .left-box {
          padding-top: 0px !important;
        }
      }

      .box2 {
        background: url("@/assets/imgs/home/step2.png") no-repeat center;
      }

      .box3 {
        background: url("@/assets/imgs/home/step3.png") no-repeat center;

        .left-box {
          padding-top: 0px !important;
        }
      }

      .box4 {
        background: url("@/assets/imgs/home/step4.png") no-repeat center;
      }

      .box5 {
        background: url("@/assets/imgs/home/step5.png") no-repeat center;
      }

      .box {
        /* height: 816px; */
        padding-top: 0px;
        height: calc(100vh - 60px);
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        display: none;

        .content {
          padding: 0 30px;

          display: flex;
          flex-direction: column;

          /* 左边文字 */
          .left-box {
            padding-top: 30px;

            .qus {
              font-size: 26px;
              color: #242424;
              margin-bottom: 20px;
            }

            .libox {
              /* display: flex;
                            align-items: center; */
              color: #9e9e9e;
              font-size: 18px;
              margin-top: 10px;

              .labelbox {
                display: flex;
                align-items: center;

                .radiobox {
                  position: relative;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  width: 15px;
                  height: 15px;
                  border: 2px solid #0862f3;
                  margin-right: 10px;

                  &:checked {
                    background: #0862f3;
                  }

                  &:checked::before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: 5px;
                    height: 3px;
                    border: 2px solid #fff;
                    transform: rotate(-45deg);
                    border-right: none;
                    border-top: none;
                  }
                }
              }
            }

            .next {
              margin-top: 34px;
              margin-bottom: 30px;
              text-align: center;
              color: #0862f3;
              width: 130px;
              height: 50px;
              line-height: 50px;
              border-radius: 30px;
              opacity: 1;
              border: 1px solid #0862f3;
            }
          }

          /* 右边图片 */
          .right-box {
            width: 260px;
            // max-width: 65vw;
            height: 35vh;
            border-radius: 20px;
          }

          .pic1 {
            background: url("@/assets/imgs/home/pic1.png") no-repeat center;
            // background-size: 100% 100%;
            background-size: cover;
          }

          .pic2 {
            background: url("@/assets/imgs/home/pic2.png") no-repeat center;
            // background-size: 100% 100%;
            background-size: cover;
          }

          .pic3 {
            background: url("@/assets/imgs/home/pic3.png") no-repeat center;
            // background-size: 100% 100%;
            background-size: cover;
          }

          .pic4 {
            background: url("@/assets/imgs/home/pic4.png") no-repeat center;
            // background-size: 100% 100%;
            background-size: cover;
          }
        }
      }

      .box5 {
        .content {
          //   display: flex;
          //   justify-content: space-between;
          //   width: 1200px;
          //   margin: 0 auto;

          .left-box {
            padding-top: 0px !important;
          }

          /* 生成报告 */
          .next {
            margin-top: 20px !important;
          }

          .image-container {
            width: 300px;
            height: 350px;
            display: flex;
            flex-wrap: wrap;

            .image-item {
              width: 30%;
              text-align: center;
              margin: 5px;
              display: flex;
              align-items: flex-end;
            }

            .image-wrapper {
              cursor: pointer;
              position: relative;
            }

            .image-item img {
              width: 100%;
            }

            /* 单选框样式 */
            .radiobox {
              margin-top: 20px;
              position: relative;
              -webkit-appearance: none;
              -moz-appearance: none;
              width: 15px;
              height: 15px;
              border: 2px solid #0862f3;
              margin-right: 10px;

              &:checked {
                background: #0862f3;
              }

              &:checked::before {
                content: "";
                position: absolute;
                top: 2px;
                left: 2px;
                width: 5px;
                height: 3px;
                border: 2px solid #fff;
                transform: rotate(-45deg);
                border-right: none;
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>