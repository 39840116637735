import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: home
  },
  {
    path: '/home',
    component: () => import('@/views/home')
  },
  {
    path: '/product',
    component: () => import('@/views/product')
  },
  {
    path: '/honor',
    component: () => import('@/views/honor')
  },
  {
    path: '/about',
    component: () => import('@/views/about')
  },
  {
    path: '/result',
    component: () => import('@/views/result')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
