<template>
  <div id="app">
    <!-- 头部导航 -->
    <div class="headerbox center " id="top">
      <div class="header">
        <div class="logo">
          <img class="imgbox imgbox1" src="../src/assets/imgs/home/logobone.png" alt="" />
          <img class="imgbox" src="../src/assets/imgs/home/logob.png" alt="" />
        </div>
        <ul class="navul">
          <li class="linav" :class="{ 'active': $route.path === v.path }" v-for="(v, i) in navlist" :key="i"
            @click="currentnav(i)">
            <router-link class="anav" :to="v.path">
              <span>{{ v.title }}</span>
            </router-link>
          </li>
          <li class="anav"> <a href="https://bl.orthodsd.cn" target="_blank">病例管理</a></li>
        </ul>
      </div>
    </div>

    <!-- 二级路由 -->
    <div class="play">
      <router-view />
    </div>

    <!-- 回顶按钮 -->
    <!-- <div class="top">
      <a class="totop" href="#top" @click="toTop">回顶</a>
    </div> -->

  </div>
</template>

<script>

export default {
  data() {
    return {
      navlist: [
        { title: "首页", path: "/home" },
        { title: "正畸产品", path: "/product" },
        { title: "企业荣誉", path: "/honor" },
        { title: "关于我们", path: "/about" },
      ],
      showBtn: false,

    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    currentnav(i) {
      let li = document.querySelectorAll(".linav")
      li.forEach((a, b) => {
        a.classList.remove("active")
        li.forEach((c, d) => {
          li[i].classList.add("active")
        })
      })
    },

    // 回到顶部
    handleScroll() {
      const sTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.showBtn = sTop > 300; // 当页面滚动超过 300px 时，显示回到顶部的按钮
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    let firstPath = window.location.pathname;
    if (firstPath == "/") {
      document.querySelectorAll(".linav")[0].classList.add("active")
    }
  },
};
</script>

<style lang="less" >
#app {

  // 头部导航
  .headerbox {
    width: 100%;
    // height: 100px;
    height: 80px;
    // background: #ccc;
    background: #fff;
     .logo .imgbox1{
      margin-right: 30px;
      // padding-bottom: 8px;
      height: 40px;
      width: 160px;
    }

   .imgbox {
      // width: 150px;
      width: 130px;
    }

    .header {
      width: 1200px;
      margin: 0 auto;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;


      .navul {
        display: flex;

        li {
          position: relative;
          cursor: pointer;
          margin-left: 30px;

          &::before {
            content: "";
            position: absolute;
            // bottom: -38px;
            bottom: -30px;
            left: 50%;
            width: 0px;
            // height: 100%;
            // height: 3px;
            // background-color: #0073d3;
            border-bottom: 4px solid #0073d3;
            transform: translateX(-50%);
            transition: all 0.4s;
          }

          &:hover::before {
            width: 100%;
          }

          span:hover {
            color: #0073d3;
          }

          a {
            // color: #333;
            color: #000;
          }
        }

        // 导航激活样式
        .active {
          a {
            color: #0073d3;
            font-weight: bold;
          }

          &.linav {
            span {
              padding-bottom: 24px;
              border-bottom: 4px solid #0073d3;
            }
          }
        }
      }
    }
  }

  .play {
    width: 100%;
  }



  .totop {
    position: fixed;
    bottom: 45%;
    right: 30px;
    width: 50px;
    height: 50px;
    // line-height: 50px;
    border-radius: 50%;
    background-color: #ccc;
    text-align: center;
    cursor: pointer;
    // opacity: 0.5;
    transition: 0.3s ease-in-out;

    .one {
      color: #fff;
      font-size: 50px;
    }

    .two {
      display: none;
      font-size: 12px;
    }
  }

  .totop:hover {
    // opacity: 1;
    background-color: #91c6d8;
    color: #000;

    .one {
      display: none;
    }

    .two {
      display: inline-block;
    }

  }

  @media only screen and (max-width: 575px) {
    .headerbox {
      height: 50px;
      // width: 100vw;
      width: 100vw !important;

    }

    .headerbox .header {
      font-size: 12px;

      .logo {
        display: none;
      }

      li {
        margin-left: 26px !important;

        &::before {
          display: none;
        }
      }

      .anav {
        // margin-right: 14px;
      }

      .navul {

        // 导航激活样式
        .active {
          a {
            color: #0073d3;
            font-weight: bold;
          }

          &.linav {
            span {
              padding-bottom: 15px;
              border-bottom: 3px solid #0073d3;
            }
          }
        }

      }
    }



    .totop {
      display: none;
      user-select: none;
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      background-color: #ccc;
      // color: #fff;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      // opacity: 0.5;
      transition: 0.3s ease-in-out;
    }

    .totop:hover {
      // opacity: 1;
      background-color: #91c6d8;
    }
  }

}</style>
